import telegramIcon from '../public/telegram.png';
import instagramIcon from '../public/instagram.svg';
import facebookIcon from '../public/facebook.svg';
import whatsappIcon from '../public/whatsapp.svg';
import tiktokIcon from '../public/tiktok.png';

const social_handles = [
	  {
		      "name": "Shavath Mart Telegram",
		          "link": "https://t.me/shavath",
			      "icon": telegramIcon
			        },
				  {
					      "name": "Shavath Mart Instagram",
					          "link": "https://instagram.com/shavathmart?utm_source=qr&igshid=MzNlNGNkZWQ4Mg==",
						      "icon": instagramIcon
						        },
							  {
								      "name": "Shavath Mart Facebook",
								          "link": "https://www.facebook.com/people/Shavath-Mart/61550216934460/?mibextid=ZbWKwL",
									      "icon": facebookIcon
									        },
										  {
											      "name": "Shavath Mart Whatsapp",
											          "link": "https://whatsapp.com/channel/0029VaDJT7iFCCoOZwGIRQ0D",
												      "icon": whatsappIcon
												        },
													  {
														      "name": "Shavath Mart Tiktok",
														          "link": "https://www.tiktok.com/@shavathmart?_r",
															      "icon": tiktokIcon
															        }
];

export default social_handles;

